<script setup>
import GlobalEmitter from '~~/glxp/utils/emitter'
import { useModalStore } from '~/store/modal'
import { useMenuStore } from '~/store/menu'

const modalStore = useModalStore()
const modalIsOpen = computed(() => modalStore.modal)
const menuStore = useMenuStore()
const menuIsOpen = computed(() => menuStore.isOpen)
function toggleMenu() {
  if (menuStore.isOpen || modalStore.modal) {
    GlobalEmitter.emit('closeModal')
    menuStore.isOpen = false
    modalStore.modal = ''
    return
  }

  menuStore.isOpen = !menuStore.isOpen
}

const uid = new Date().getTime()
</script>

<template>
  <button
    class="menu-toggle gtm-vgms-header-menu-hamburger"
    :class="{ 'menu-toggle--open': modalIsOpen || menuIsOpen }"
    @click="toggleMenu"
  >
    <span class="u-visually-hidden">Toggle Menu</span>
    <svg viewBox="-21 -21 42 42">
      <defs>
        <clipPath :id="`outline-clip-${uid}`">
          <circle cx="0" cy="0" r="21" />
        </clipPath>

        <linearGradient
          :id="`outline-gradient-${uid}`"
          x1="0%"
          y1="0%"
          x2="0%"
          y2="0%"
        >
          <stop offset="0%" stop-color="#6f19f7" />
          <stop offset="100%" stop-color="#c80afb" />
        </linearGradient>

        <linearGradient
          :id="`highlight-gradient-${uid}`"
          x1="0%"
          y1="0%"
          x2="100%"
          y2="0%"
        >
          <stop offset="0%" stop-color="#ffffff" stop-opacity="0.4" />
          <stop offset="25%" stop-color="#ffffff" stop-opacity="0" />
        </linearGradient>
      </defs>

      <circle
        class="menu-toggle__outline"
        cx="0"
        cy="0"
        r="21"
        :stroke="`url(#outline-gradient-${uid})`"
        :clip-path="`url(#outline-clip-${uid})`"
      />
      <g class="menu-toggle__highlight-group">
        <circle
          class="menu-toggle__highlight menu-toggle__highlight--1"
          cx="0"
          cy="0"
          r="21"
          :stroke="`url(#highlight-gradient-${uid})`"
          :clip-path="`url(#outline-clip-${uid})`"
        />
        <circle
          class="menu-toggle__highlight menu-toggle__highlight--2"
          cx="0"
          cy="0"
          r="21"
          :stroke="`url(#highlight-gradient-${uid})`"
          :clip-path="`url(#outline-clip-${uid})`"
        />
      </g>
    </svg>

    <span class="menu-toggle__dots-wrapper">
      <span class="menu-toggle__dots">
        <span class="menu-toggle__dot"></span>
        <span class="menu-toggle__dot"></span>
        <span class="menu-toggle__dot"></span>
        <span class="menu-toggle__dot"></span>
      </span>
    </span>
  </button>
</template>

<style lang="scss">
@import 'styles/utils/variables';
@import 'styles/utils/timing-functions';

.menu-toggle {
  --size: 46px;
  border-radius: 50%;

  position: relative;
  width: var(--size);
  height: var(--size);
  display: block;

  svg {
    height: 100%;
    width: 100%;
  }

  &__outline {
    stroke-width: 3px;
    stroke: $brand-magenta;
    transition: stroke-width 0.3s ease-out;
    fill: transparent;
    transition: opacity 0.3s ease-out;
  }

  &__highlight {
    stroke-width: 3px;
    fill: transparent;
    opacity: 0;
    &--2 {
      transform: rotate(180deg);
    }
    &-group {
      transition: transform 0.3s ease, opacity 0.3s ease;
    }
  }

  &__dott {
    fill: white;
  }

  &__dots {
    position: relative;
    width: 100%;
    height: 100%;
    max-width: 10px;
    max-height: 10px;
    display: block;
    transition: max-width 0.25s $ease-out-quad, max-height 0.25s $ease-out-quad,
      transform 0.25s $ease-out-quad;

    &-wrapper {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 10px;
      height: 10px;
      max-width: 10px;
      max-height: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      transform: translate(-50%, -50%);
      transition: max-width 0.25s $ease-out-quad,
        max-height 0.25s $ease-out-quad, transform 0.25s $ease-out-quad;
    }
  }

  &__dot {
    position: absolute;
    width: 3px;
    height: 3px;
    border-radius: 50%;
    display: block;
    background-color: #fff;

    &::before {
      position: absolute;
      top: calc(50% - 0.5px);
      right: 1px;
      content: '';
      height: 1px;
      width: 7px;
      transform: translateX(0%) scaleX(0);
      background: #fff;
      transform-origin: left center;
      transition: transform 0.25s $ease-out-quad;
    }

    &:nth-child(n + 3) {
      bottom: 0;
    }

    &:nth-child(even) {
      left: 0;
    }

    &:nth-child(odd) {
      right: 0;
    }

    &:first-child {
      transform: rotate(135deg);
    }

    &:nth-child(2) {
      transform: rotate(45deg);
    }

    &:nth-child(3) {
      transform: rotate(-135deg);
    }

    &:nth-child(4) {
      transform: rotate(-45deg);
    }
  }

  &:not(.menu-toggle--open) {
    &:hover {
      .menu-toggle__dots {
        &-wrapper {
          animation: 0.4s $ease-out-quad forwards menuToggleHover;
        }
      }
    }
  }

  @keyframes menuToggleHover {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
      max-height: 10px;
      max-width: 10px;
      transform-origin: center center;
    }
    50% {
      transform: translate(-50%, -50%);
      max-height: 6px;
      max-width: 6px;
      transform-origin: center center;
    }
    100% {
      transform: translate(-50%, -50%) rotate(90deg);
      max-height: 10px;
      max-width: 10px;
      transform-origin: center center;
    }
  }

  &--open {
    .menu-toggle__outline {
      opacity: 0;
    }
    .menu-toggle__highlight {
      opacity: 1;
    }
    .menu-toggle__highlight-group {
      transform: rotate(225deg);
    }
    .menu-toggle__dots {
      max-height: 2px;
      max-width: 2px;
    }
    .menu-toggle__dot {
      background: transparent;
      transition: background 0.1s linear 0.1s;
      &::before {
        transform: translateX(0) scaleX(1);
      }
    }

    &:hover {
      .menu-toggle__highlight-group {
        transition: transform 0.35s $ease-out-quad;
        transform: rotate(415deg) !important;
      }
    }
  }
}
</style>
